const assets = `
... on pageBuilder_Asset {
  id
  url
  extension
  ctaPosition
  fallbackImage {
    id
    url
  }
  ctaText
  ctaUrl
  alt
  vimeoUrl
  bodyBasic
}
`
export default assets;
