var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._g(
      {
        staticClass: "product-image",
        class: {
          "product-image--height": _vm.basic,
          "product-image--width": !_vm.basic
        },
        style: _vm.style,
        attrs: {
          href: _vm.fancybox
            ? !_vm.highQualityImageError
              ? _vm.image.src
              : !_vm.lowerQualityImageError
              ? _vm.image.loading
              : _vm.placeholder
            : false,
          "data-fancybox": _vm.fancybox ? "gallery" : false
        }
      },
      _vm.$listeners
    ),
    [
      _c("img", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showPlaceholder,
            expression: "showPlaceholder"
          }
        ],
        staticClass: "product-image__placeholder",
        attrs: { src: _vm.placeholder, alt: _vm.alt }
      }),
      _vm._v(" "),
      !_vm.lowerQualityImageError || _vm.isOnline
        ? _c("img", {
            ref: "lQ",
            staticClass: "product-image__thumb",
            class: { "soft-hide": !_vm.showLowerQuality },
            attrs: { src: _vm.image.loading, alt: _vm.alt },
            on: {
              load: function($event) {
                return _vm.imageLoaded("lower", true)
              },
              error: function($event) {
                return _vm.imageLoaded("lower", false)
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.highQualityImageError || _vm.isOnline
        ? _c("img", {
            staticClass: "product-image__thumb",
            class: { "soft-hide": !_vm.showHighQuality },
            attrs: { src: _vm.image.src, alt: _vm.alt },
            on: {
              load: function($event) {
                return _vm.imageLoaded("high", true)
              },
              error: function($event) {
                return _vm.imageLoaded("high", false)
              }
            }
          })
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }