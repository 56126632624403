import { render, staticRenderFns } from "./ProductAttribute.vue?vue&type=template&id=2253be57&"
import script from "./ProductAttribute.vue?vue&type=script&lang=js&"
export * from "./ProductAttribute.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/vsf/releases/SddMW2d3a1/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('2253be57', component.options)
    } else {
      api.reload('2253be57', component.options)
    }
    module.hot.accept("./ProductAttribute.vue?vue&type=template&id=2253be57&", function () {
      api.rerender('2253be57', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/themes/ribble/components/core/ProductAttribute.vue"
export default component.exports