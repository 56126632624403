var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "observe-visibility",
          rawName: "v-observe-visibility",
          value: _vm.visibilityChanged,
          expression: "visibilityChanged"
        }
      ],
      staticClass: "product align-center w-100 pb20"
    },
    [
      _c(
        "div",
        { staticClass: "product__icons" },
        [
          _c("AddToWishlist", { attrs: { product: _vm.product } }, [
            _c(
              "div",
              {
                staticClass: "product__icon",
                class: { "product__icon--active": _vm.isOnWishlist },
                attrs: {
                  title: _vm.isOnWishlist
                    ? _vm.$t("Remove")
                    : _vm.$t("Add to favorite")
                }
              },
              [
                _c("i", { staticClass: "material-icons" }, [
                  _vm._v(_vm._s(_vm.favoriteIcon))
                ])
              ]
            )
          ]),
          _vm._v(" "),
          _c("AddToCompare", { attrs: { product: _vm.product } }, [
            _c(
              "div",
              {
                staticClass: "product__icon",
                class: { "product__icon--active": _vm.isOnCompare },
                attrs: {
                  title: _vm.isOnCompare
                    ? _vm.$t("Remove from compare")
                    : _vm.$t("Add to compare")
                }
              },
              [_c("i", { staticClass: "material-icons" }, [_vm._v("compare")])]
            )
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "router-link",
        {
          staticClass: "block no-underline product-link align-left",
          attrs: {
            to: _vm.productLink,
            "data-testid": "product-link",
            "data-stock-status": _vm.isInStock ? "instock" : "outofstock"
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "product-cover bg-cl-white",
              class: [
                { sale: _vm.labelsActive && _vm.isOnSale },
                { new: _vm.labelsActive && _vm.isNew }
              ]
            },
            [
              _c("product-image", {
                staticClass: "product-cover__thumb",
                attrs: {
                  image: _vm.thumbnailObj,
                  alt: _vm._f("htmlDecode")(_vm.product.name),
                  "calc-ratio": false,
                  "data-testid": "productImage"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "product-details" },
            [
              !_vm.onlyImage
                ? _c("h4", { staticClass: "mt10 product-details__name" }, [
                    _c("span", { staticClass: "manufacturer" }, [
                      _vm._v(
                        _vm._s(
                          _vm.getAttrOptionLabel(
                            "manufacturer",
                            _vm.product.manufacturer
                          )
                        ) + " "
                      )
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "name" }, [
                      _vm._v(_vm._s(_vm._f("htmlDecode")(_vm.product.name)))
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "product-details__swatches" },
                [
                  _vm.product.configurable_options
                    ? _c("product-color-switcher", {
                        attrs: { children: _vm.product.configurable_options },
                        on: {
                          mouseover: function($event) {
                            return _vm.changeImage($event)
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c("PriceWasSave", {
                attrs: { product: _vm.product, "text-size": "text-base" }
              }),
              _vm._v(" "),
              _vm.product.reviews
                ? _c(
                    "div",
                    {
                      staticClass:
                        "mb15 mt5 uppercase text-color-secondary product-rating w-100 product-details__reviews"
                    },
                    [
                      _c("div", { staticClass: "review-rating" }, [
                        _c("div", { staticClass: "rating-summary" }, [
                          _c("span", {
                            style: { width: _vm.product.reviews.rating + "%" }
                          })
                        ])
                      ])
                    ]
                  )
                : _vm._e()
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }