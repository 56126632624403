const heroBlock = `
... on pageBuilder_hero_BlockType {
  id
  width
  headingStyles
  height
  typeHandle
  headingOne
  headingTwo
  ctaText
  ctaUrl
  image {
    ... on heros_Asset {
      id
      url
    }
  }
  mobileImage {
    ... on heros_Asset {
      id
      url
    }
  }
  video {
    ... on heros_Asset {
      id
      url
    }
  }
  mobileVideo {
    ... on heros_Asset {
      id
      url
    }
  }
}
`;

export default heroBlock;
