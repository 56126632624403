var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "links" },
    _vm._l(_vm.products, function(productLink, index) {
      return _c("div", { key: index, staticClass: "between-md" }, [
        productLink.product
          ? _c("div", { staticClass: "link-container" }, [
              _c("div", { staticClass: "row middle-xs h4 link-details" }, [
                productLink.product.special_price &&
                productLink.product.price_incl_tax &&
                productLink.product.original_price_incl_tax
                  ? _c("span", { staticClass: "price-details" }, [
                      _c("span", { staticClass: "m0" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm._f("htmlDecode")(productLink.product.name)
                            ) +
                            "\n          "
                        )
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "price" }, [
                        _c("span", { staticClass: "price-special" }, [
                          _vm._v(
                            _vm._s(
                              _vm.localisePrice(
                                productLink.product.price_incl_tax,
                                _vm.storeView
                              )
                            )
                          )
                        ]),
                        _vm._v(" \n            "),
                        _c("span", { staticClass: "price-original" }, [
                          _vm._v(
                            _vm._s(
                              _vm.localisePrice(
                                productLink.product.original_price_incl_tax,
                                _vm.storeView
                              )
                            )
                          )
                        ])
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !productLink.product.special_price &&
                productLink.product.price_incl_tax
                  ? _c("span", { staticClass: "price-details" }, [
                      _c("span", { staticClass: "m0" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm._f("htmlDecode")(productLink.product.name)
                            ) +
                            "\n          "
                        )
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "price" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.localisePrice(
                                productLink.product.price_incl_tax,
                                _vm.storeView
                              )
                            ) +
                            "\n          "
                        )
                      ])
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              productLink.product
                ? _c("div", { staticClass: "link-qty cl-bg-tertiary " }, [
                    _c("p", { staticClass: "m0 text" }, [
                      _vm._v(
                        "\n          " + _vm._s(_vm.$t("Qty")) + "\n        "
                      )
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.number",
                          value: productLink.product.qty,
                          expression: "productLink.product.qty",
                          modifiers: { number: true }
                        }
                      ],
                      staticClass: "product-qty",
                      attrs: { type: "text", min: "1", autofocus: "" },
                      domProps: { value: productLink.product.qty },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            productLink.product,
                            "qty",
                            _vm._n($event.target.value)
                          )
                        },
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    })
                  ])
                : _vm._e()
            ])
          : _vm._e()
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }