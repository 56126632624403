var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    { staticClass: "custom-options" },
    _vm._l(_vm.product.custom_options, function(option) {
      return _c(
        "div",
        { key: "customOption_" + option.option_id, staticClass: "between-md" },
        [
          _c("div", { staticClass: "custom-option link-container" }, [
            _c("h4", [_vm._v(_vm._s(option.title))]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "row middle-xs h4 link-details" },
              [
                option.type === "field"
                  ? _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value:
                            _vm.inputValues["customOption_" + option.option_id],
                          expression:
                            "inputValues[('customOption_' + option.option_id)]"
                        }
                      ],
                      staticClass: "w-100 border-box brdr-none h4 sans-serif",
                      attrs: {
                        type: "text",
                        name: "customOption_" + option.option_id,
                        focus: "",
                        placeholder: option.title
                      },
                      domProps: {
                        value:
                          _vm.inputValues["customOption_" + option.option_id]
                      },
                      on: {
                        change: function($event) {
                          return _vm.optionChanged(option)
                        },
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.inputValues,
                            "customOption_" + option.option_id,
                            $event.target.value
                          )
                        }
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm._l(option.values, function(opval) {
                  return option.type === "radio" ||
                    option.type === "select" ||
                    option.type === "drop_down"
                    ? _c(
                        "div",
                        {
                          key: opval.option_type_id,
                          staticClass: "relative price-details"
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value:
                                  _vm.inputValues[
                                    "customOption_" + option.option_id
                                  ],
                                expression:
                                  "inputValues[('customOption_' + option.option_id)]"
                              }
                            ],
                            staticClass: "m0 no-outline",
                            attrs: {
                              type: "radio",
                              name: "customOption_" + option.option_id,
                              id: "customOption_" + opval.option_type_id,
                              focus: ""
                            },
                            domProps: {
                              value: opval.option_type_id,
                              checked: _vm._q(
                                _vm.inputValues[
                                  "customOption_" + option.option_id
                                ],
                                opval.option_type_id
                              )
                            },
                            on: {
                              change: [
                                function($event) {
                                  _vm.$set(
                                    _vm.inputValues,
                                    "customOption_" + option.option_id,
                                    opval.option_type_id
                                  )
                                },
                                function($event) {
                                  return _vm.optionChanged(option)
                                }
                              ]
                            }
                          }),
                          _c("label", {
                            attrs: {
                              for: "customOption_" + opval.option_type_id
                            },
                            domProps: { innerHTML: _vm._s(opval.title) }
                          })
                        ]
                      )
                    : _vm._e()
                }),
                _vm._v(" "),
                _vm._l(option.values, function(opval) {
                  return option.type === "checkbox"
                    ? _c(
                        "div",
                        {
                          key: opval.option_type_id,
                          staticClass: "relative price-details"
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value:
                                  _vm.inputValues[
                                    "customOption_" + option.option_id
                                  ],
                                expression:
                                  "inputValues[('customOption_' + option.option_id)]"
                              }
                            ],
                            staticClass: "m0 no-outline",
                            attrs: {
                              type: "checkbox",
                              name: "customOption_" + option.option_id,
                              id: "customOption_" + opval.option_type_id,
                              focus: ""
                            },
                            domProps: {
                              value: opval.option_type_id,
                              checked: Array.isArray(
                                _vm.inputValues[
                                  "customOption_" + option.option_id
                                ]
                              )
                                ? _vm._i(
                                    _vm.inputValues[
                                      "customOption_" + option.option_id
                                    ],
                                    opval.option_type_id
                                  ) > -1
                                : _vm.inputValues[
                                    "customOption_" + option.option_id
                                  ]
                            },
                            on: {
                              change: [
                                function($event) {
                                  var $$a =
                                      _vm.inputValues[
                                        "customOption_" + option.option_id
                                      ],
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = opval.option_type_id,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.inputValues,
                                          "customOption_" + option.option_id,
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.inputValues,
                                          "customOption_" + option.option_id,
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.inputValues,
                                      "customOption_" + option.option_id,
                                      $$c
                                    )
                                  }
                                },
                                function($event) {
                                  return _vm.optionChanged(option)
                                }
                              ]
                            }
                          }),
                          _c("label", {
                            attrs: {
                              for: "customOption_" + opval.option_type_id
                            },
                            domProps: { innerHTML: _vm._s(opval.title) }
                          })
                        ]
                      )
                    : _vm._e()
                })
              ],
              2
            ),
            _vm._v(" "),
            _vm.validation.results["customOption_" + option.option_id].error
              ? _c("span", { staticClass: "error" }, [
                  _vm._v(
                    _vm._s(
                      _vm.validation.results["customOption_" + option.option_id]
                        .message
                    )
                  )
                ])
              : _vm._e()
          ])
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }