var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.enableMobileCarousel && _vm.isMobile
    ? _c(
        "div",
        { staticClass: "product-listing" },
        [
          _c(
            "carousel",
            {
              attrs: {
                "per-page": 1,
                controls: false,
                "indicator-type": "disc"
              }
            },
            _vm._l(_vm.productsCapped, function(product) {
              return _c(
                "slide",
                { key: product.id },
                [_c("product-tile", { attrs: { product: product } })],
                1
              )
            }),
            1
          )
        ],
        1
      )
    : _c(
        "div",
        {
          staticClass: "product-listing",
          class: "grid-columns-" + _vm.columns
        },
        _vm._l(_vm.productsCapped, function(product, index) {
          return _c(
            "div",
            {
              key: product.id + "-" + index,
              staticClass: "flex u-margin-bottom"
            },
            [_c("product-tile", { attrs: { product: product } })],
            1
          )
        }),
        0
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }