var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "custom-option" }, [
    _c("div", { staticClass: "between-md" }, [
      _c("div", { staticClass: "custom-option link-container" }, [
        _c("h4", [_vm._v(" " + _vm._s(_vm.option.title) + " ")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "row middle-xs h4 link-details" },
          _vm._l(_vm.option.product_links, function(opval) {
            return _c(
              "div",
              { key: opval.id, staticClass: "relative price-details" },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.productOptionId,
                      expression: "productOptionId"
                    }
                  ],
                  staticClass: "m0 no-outline",
                  attrs: {
                    type: "radio",
                    name: _vm.bundleOptionName + opval.id,
                    id: _vm.bundleOptionName + opval.id,
                    focus: ""
                  },
                  domProps: {
                    value: opval.id,
                    checked: _vm._q(_vm.productOptionId, opval.id)
                  },
                  on: {
                    change: function($event) {
                      _vm.productOptionId = opval.id
                    }
                  }
                }),
                _vm._v(" "),
                opval.product
                  ? _c("label", {
                      staticClass: "m0 pointer",
                      attrs: { for: _vm.bundleOptionName + opval.id },
                      domProps: { innerHTML: _vm._s(opval.product.name) }
                    })
                  : _vm._e()
              ]
            )
          }),
          0
        ),
        _vm._v(" "),
        _c("div", { staticClass: "link-qty cl-bg-tertiary" }, [
          _c(
            "label",
            { staticClass: "text m0", attrs: { for: _vm.quantityName } },
            [_vm._v(_vm._s(_vm.$t("Qty")))]
          ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.quantity,
                expression: "quantity"
              }
            ],
            staticClass: "product-qty",
            attrs: {
              type: "number",
              min: "0",
              disabled: !_vm.canChangeQty,
              name: _vm.quantityName,
              id: _vm.quantityName,
              focus: ""
            },
            domProps: { value: _vm.quantity },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.quantity = $event.target.value
              }
            }
          })
        ])
      ])
    ]),
    _vm._v(" "),
    _vm.errorMessage
      ? _c("span", { staticClass: "error" }, [_vm._v(_vm._s(_vm.errorMessage))])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }