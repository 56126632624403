import axios from 'axios';
import config from 'config';
import actions from './cart/actions';

type IpDataType = {
  ip: string;
  isStoreIp: boolean;
  error: { message: string };
};

const query = `
query {
  getPayInStoreConfig {
    allowed_ips
  }
}
`;

const m2GraphqlUrl = config.m2GraphqlUrl;

export const storeIpStore = {
  namespaced: true,
  state: {
    error: '',
    ipData: null
  },
  mutations: {
    SET_IP_DATA(state, ipData) {
      state.ipData = ipData;
    },
    SET_ERROR(state, error) {
      state.error = error;
    }
  },
  actions: {
    async fetchStoreIp({ commit }) {
      try {
        const magentoRes = await axios.post(m2GraphqlUrl, {
          query
        });
        const ipResponse: { data: {ip: string} } = await axios.get(
          'https://api.ipify.org/?format=json'
        );
        const magentoResData = magentoRes.data && magentoRes.data.data ? magentoRes.data.data : null;
        if (!magentoResData) {
          throw new Error('Error fetching store IP config from Magento');
        }
        const storeIps = magentoResData.getPayInStoreConfig ? magentoResData.getPayInStoreConfig.allowed_ips : [];
        const data: IpDataType = {
          ip: ipResponse.data.ip,
          isStoreIp: storeIps.includes(ipResponse.data.ip),
          error: { message: '' }
        };
        console.info('storeIpData', data);
        commit('SET_IP_DATA', data);
      } catch (error) {
        console.error(error);
        const e = "Error fetching store IP config from Magento";
        const data: IpDataType = {
          ip: "",
          isStoreIp: false,
          error: { message: e }
        };
        commit('SET_IP_DATA', data);
        commit('SET_ERROR', e);
      }
    }
  },
  getters: {
    getIsStoreIp: state => state.ipData ? state.ipData.isStoreIp : false,
  }
};
