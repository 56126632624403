var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.children
    ? _c(
        "div",
        { staticClass: "productColorSwitcher" },
        _vm._l(_vm.children, function(variation) {
          return _c("div", { key: variation.id }, [
            variation.attribute_code.match(/color|colour/)
              ? _c(
                  "div",
                  _vm._l(variation.values, function(option) {
                    return _c(
                      "button",
                      {
                        key: option.id,
                        staticClass:
                          "mr6 mb3 bg-cl-transparent brdr-1 brdr-cl-transparent :brdr-cl-bg-primary relative inline-flex pointer color",
                        attrs: {
                          "aria-label": _vm.$t("Select color {label}", {
                            label: option.label
                          })
                        },
                        on: {
                          mouseover: function($event) {
                            return _vm.$emit("mouseover", option)
                          }
                        }
                      },
                      [
                        _c("span", {
                          staticClass:
                            "absolute brdr-1 brdr-cl-primary block color-inside",
                          style: _vm.colorFrom(option.label)
                        })
                      ]
                    )
                  }),
                  0
                )
              : _vm._e()
          ])
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }