var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass:
        "p0 inline-flex middle-xs bg-cl-transparent brdr-none h5 pointer cl-secondary",
      attrs: { type: "button", "data-testid": "addToCompare" },
      on: {
        click: function($event) {
          _vm.isOnCompare
            ? _vm.removeProduct(_vm.product)
            : _vm.addProduct(_vm.product)
        }
      }
    },
    [
      _vm._t("default", [
        _c("i", { staticClass: "pr5 material-icons" }),
        _vm._v(" "),
        !_vm.isOnCompare
          ? [_vm._v("\n      " + _vm._s(_vm.$t("Add to compare")) + "\n    ")]
          : [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("Remove from compare")) + "\n    "
              )
            ]
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }