var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.product.special_price_incl_tax !== 0
        ? _c(
            "div",
            {
              staticClass: "font-bold leading-none font-featured mb-2",
              class: _vm.textSize
            },
            [
              _vm._v(
                "\n    " +
                  _vm._s(
                    _vm.localisePrice(
                      _vm.product.special_price_incl_tax,
                      _vm.storeView
                    )
                  ) +
                  "\n  "
              )
            ]
          )
        : _c(
            "div",
            {
              staticClass: "font-bold leading-none font-featured mb-2",
              class: _vm.textSize
            },
            [
              _vm.fromPrice
                ? _c("span", { staticClass: "mr-1" }, [
                    _vm._v(_vm._s(_vm.$t("From")) + ":")
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.localisePrice(
                      _vm.product.final_price_incl_tax,
                      _vm.storeView
                    )
                  )
                )
              ])
            ]
          ),
      _vm._v(" "),
      _vm.product.special_price_incl_tax !== 0
        ? [
            _c("div", { staticClass: "leading-none mb-1" }, [
              _vm._v(
                "\n      Was " +
                  _vm._s(
                    _vm.localisePrice(
                      _vm.product.original_price_incl_tax,
                      _vm.storeView
                    )
                  ) +
                  "\n    "
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "font-bold leading-none font-featured text-red-500"
              },
              [
                _vm._v(
                  "\n      Save " +
                    _vm._s(_vm.localisePrice(_vm.saving, _vm.storeView)) +
                    "\n    "
                )
              ]
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }