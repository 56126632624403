import { render, staticRenderFns } from "./AddToWishlist.vue?vue&type=template&id=495cfce6&"
import script from "./AddToWishlist.vue?vue&type=script&lang=js&"
export * from "./AddToWishlist.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/vsf/releases/SddMW2d3a1/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('495cfce6', component.options)
    } else {
      api.reload('495cfce6', component.options)
    }
    module.hot.accept("./AddToWishlist.vue?vue&type=template&id=495cfce6&", function () {
      api.rerender('495cfce6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/themes/ribble/components/core/blocks/Wishlist/AddToWishlist.vue"
export default component.exports